/* auth.css */

.form-heading {
  font-size: 1.5rem;
  text-align: center;
  color: #1308a8;
  font-weight: bold;
  font: bold;
  margin-top: 50px; /*this lowers the form heading*/
  margin-bottom: 70px;
}

.signup-page {
  min-height: 100vh;
  padding-top: 3%;
  background-color: #46e3c3;
}

.signup-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  z-index: 0; 
  padding-bottom: 500px; /*rais*/
}

.signup-form-container {
  position: relative;
  width: 600px; /* Changed to a fixed value */
  z-index: 1;
}

.signup-form-container::before {
  content: "";
  position: absolute;
  width: 100%; /* Changed to 100% */
  height: 100%; /* Changed to 100% */
  background-color: black;
  right: -10px; /* Adjust this to control the distance from the right edge of the form */
  bottom: -14px; /* Adjust this to control the distance from the bottom edge of the form */
  border-radius: 9px;
  z-index: 0; /* Set the z-index to 0 to position the shadow behind the form but above the background */
}

.form-container {
  background-color: #ffff00;
  padding: 40px;
  border-radius: 8px;
  width: 100%;
  max-width: 100%; /* Limit the form's maximum width to 400px */
  margin: 0 auto; /* Center the form horizontally within the parent container */
  position: relative; /* Add this to position the form above the shadow */
  z-index: 2; /* Set a higher z-index to keep the form above the shadow */
}


.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Full width of the container */
  position: relative;
  padding-bottom: 10px;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
  font-family: SegoeUI, sans-serif;
  font-size: 35px;
  font-weight: bold;
  color: black;
}

.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}

.input-container input {
  padding: 10px;
  width: 100%;
  border: 1px solid black; /* Thin black border */
  border-radius: 4px; /* Keeps the edges smooth */
  outline: none;
}

.form-label {
  font-weight: bold;
  margin-bottom: 5px;
  font: bold;
}

.hideShowIcon {
  position: absolute;
  right: 10px;
  top: 45px; /* Adjust accordingly */
  cursor: pointer;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligns content to the left */
  width: 100%; /* Ensures the container spans the full width */
  margin-top: 15px;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px; /* Adds space to the right of the checkbox */
  margin-bottom: 5px; /* helps align checkbox with text */
}

.signup-form p {
  margin: 20px 0 0;
}

.submit-button {
  position: relative;
  padding-left: 50px;
  padding-right: 20px;
  background-color: #1308a8;
  color: white;
  padding: 0.5rem 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
  border-radius: 0.375rem;
  width: 100%;
  height: 2.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-decoration: none; /* Add this line */
}

.signup-form-shadow {
  position: absolute;
  width: 30%;
  height: 90%;
  background-color: black;
  right: 10px; /* Adjust this to control the distance from the right edge of the form */
  bottom: -14px; /* Adjust this to control the distance from the bottom edge of the form */
  border-radius: 9px;
  z-index: -1; /* Set the z-index to -1 to position the shadow behind the form */
}

@media (max-width: 800px) {
  .signup-form-container {
    /* Change back to block to allow full width on smaller screens */
    display: block;
    max-width: 100%; /* Set the form container to fill the entire width of its parent when the screen width is less than or equal to 800px */
  }

  .signup-form-shadow {
    display: none; /* Hide the shadow when the screen width is less than or equal to 800px */
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-button:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spin .6s linear infinite;
}


.linkContainer {
  font-family: 'Arial', sans-serif;
  text-align: center;
  margin-top: 20px;
}

.linkContainer p {
  margin: 10px 0; /* Add some vertical spacing between paragraphs */
}

.linkContainer a {
  font-weight: bold;
  color: #1308a8; /* Using the blue from your previous button for consistency */
  text-decoration: none;
  transition: color 0.3s ease;
}

.linkContainer a:hover {
  color: darkblue;
}
