.upgrade-button {
    position: relative;
    background-color: #1308a8;
    color: white;
    padding: 0.5rem 1rem; /* Adjusted padding */
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 0.5rem; /* Adjusted margin-right */
    margin-left: 0.5rem; /* Adjusted margin-left */
    border-radius: 0.375rem;
    max-width: 50%; /* Max width added */
    min-width: 50%;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    gap: 5px;
    overflow: hidden; /* Ensuring spinner doesn't go outside */
    text-decoration: none;
}


.current-plan-button {
    position: relative;
    background-color: #46e3c3;
    color: white;
    padding: 0.5rem 1rem; /* Adjusted padding */
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 0.5rem; /* Adjusted margin-right */
    margin-left: 0.5rem; /* Adjusted margin-left */
    border-radius: 0.375rem;
    max-width: 50%; /* Max width added */
    min-width: 50%;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    gap: 5px;
    overflow: hidden; /* Ensuring spinner doesn't go outside */
    text-decoration: none;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.upgrading-button:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: white; /* Change color to white */
    animation: spin .6s linear infinite;
}
