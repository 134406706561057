.verify-form-heading {
    font-size: 1.5rem;
    text-align: center;
    color: #1308a8;
    font-weight: bold;
    font: bold;
    margin-bottom: 20.0rem; /* Adjust this value if you want more space between the heading and the form */
  }

.loading-button:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spin .6s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
