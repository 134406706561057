.menu-title{
    color: #ecf500;
    font-weight: bold;
    padding-left: 20px;
}

.tab-title{
    color: #000;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'Segoe UI', sans-serif;
    font-weight: bold;
    letter-spacing: normal;
}


.tab-section-title {
    color: #000;
    margin-bottom: 10px;
    margin-top: 30px;
    font-size: 20px;
    font-family: 'Segoe UI', sans-serif;
    font-weight: bold;
    letter-spacing: normal;
    
}

.input-field {
    margin-bottom: 10px;
    margin-left: 2px;
    display: 'flex';
    flex-direction: 'column';
    padding-bottom: '10px';
  }
  
.tiles-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 5px;
    margin-left: 2px;
  }


.tile.selected {
    background-color: #ecf500; /* yellow for selected state */
    color: black;
}

.tile.selected:hover {
  background-color: #ecf500; /* yellow */
  color: black;
}

.tile.language.selected {
  background-color: #ecf500; /* yellow for selected state */
  color: black;
}

.code-bash-screen {
    background-color: var(--dracula-background);
    margin: 10px 0;
    margin-left: 5px;
    max-width: 475px;
    min-height: 100px;
    font-family: monospace;
    font-size: 12px;
    overflow-wrap: break-word;
    white-space: normal; /* ensure that whitespace is not preventing wrapping */
    border-radius: 10px;
    overflow: auto;
    user-select: none;
}



.response-bash-screen {
    background-color: black;
    color: #ffffff;
    padding: 5px;  /* Consistent padding */
    margin: 0px;
    font-family: monospace;
    font-size: 12px;
    min-height: 100px;
    word-wrap: break-word;
    border-radius: 10px;
    overflow: auto;
    width: 93%; 
}


:root {
    --dracula-background: #282a36;
    --dracula-current-line: #44475a;
    --dracula-foreground: #f8f8f2;
    --dracula-comment: #6272a4;
    --dracula-cyan: #8be9fd;
    --dracula-green: #50fa7b;
    --dracula-orange: #ffb86c;
    --dracula-pink: #ff79c6;
    --dracula-purple: #bd93f9;
    --dracula-red: #ff5555;
    --dracula-yellow: #f1fa8c;
}

.code-container {
    background: var(--dracula-background);
    color: var(--dracula-foreground);
    padding: 20px;
    border-radius: 10px;
    font-family: 'Fira Code', 'Consolas', monospace;
    font-size: 14px;
    overflow-x: auto;
    height: 100%;
    width: 100%
  }


pre {
    margin: 0;    /* Remove default margins */
    padding: 0;   /* Ensure it fills its container */
    width: 100%;  /* Takes full width of its parent */
    height: 100%; /* Takes full height of its parent */
}

code {
    display: block;
    padding: 1rem;      /* Add some padding for better visibility */
    margin: 0;
    overflow: inherit;  /* Allow overflow to be handled by the parent */
}

.info-icon {
    color: white;
    background-color: black; 
    border-radius: 15px; 
    margin-left: 10px;
    margin-bottom: 2px;
}
  
.info-icon .MuiSvgIcon-root {
    fill: white;
}

.input-label{
    font-family: 'Segoe UI', sans-serif;
    font-weight: bold;
    color: black;
    margin-left: 12px; /* Add left margin */
    font-size: 14px;
    margin-left: 10px;
}

.builder-card-2-input-label{
    font-family: 'Segoe UI', sans-serif;
    font-weight: bold;
    color: black;
    margin-left: 6px; /* Add left margin */
    font-size: 14px;
    margin-bottom: 5px;
}

.builder-card-container {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 1px;
}

.builder-card-1 {
    width: 50%; /* or any specific width you want */
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.builder-card-2 {
    width: 50%; /* or any specific width you want */
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.builder-card-3 {
    width: 572px;
    overflow: auto;
    display: flex;
    flex-direction: column;
}


.builder-card-1, .builder-card-2 {
    box-shadow: none; /* Removes any default shadow that might create a border-like appearance */
}


.builder-card {
    min-width: 570px;
    min-height: 365px; /* Adjust as needed */
    padding: 0; /* Add this */
    margin: 0; /* Add this */
}


.response-container {
    display: 'flex';
    flex-direction: 'column';
    max-width: '400px';
    margin: '0 auto';
}


.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

.builder-card-2-buttons {
    margin-top: 50px;
    margin-left: 5px;
}

/*removes the boarders from cards using double classnames makes styles more likely to take effect*/
.builder-card-1.builder-card-1, .builder-card-3.builder-card-3, .builder-card-2.builder-card-2{
    background-color: white;
    border: none;
    box-shadow: none;
}


.main-content-wrapper {
    display: flex;
    overflow-x: hidden; /* Prevent horizontal scroll */
    width: 100vw;       /* Take full viewport width */
  }

.drawer-fixed {
    flex: 0 0 100px;  /* Fixed width and don't allow it to grow or shrink */
  }

.content-area {
    flex: 1;          /* Take remaining space */
    overflow-x: hidden; /* Prevent horizontal scroll */
    overflow-y: auto;   /* Allow vertical scroll if needed */
    width: calc(100vw - 100px); /* Viewport width minus the drawer width */
  }


  .icon-text-wrapper {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.2s;
    margin-bottom: 13px;
    padding: 10px;
  }

  .text-content {
    display: flex;
    flex-direction: column;
    margin-left: 7px; /* Add spacing between icon and text content */
    font-size: 11px;
  }
  
  .bold-text {
    font-weight: bold;
    padding-right:10px;
    min-width: 120px;
  }

.icon-text-wrapper:hover {
    background-color: #f7f7f7; /* Slight color change on hover for a button effect */
}


.circular-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: #e0e0e0; /* Gray background */
    border-radius: 55%; /* This makes it circular */
    margin-right: 5px; /* Space between the icon and text */
}


.period-info-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    height: 50px;
    padding: 5px;
    border: 1px solid #ddd; /* light border */
    box-shadow: 2px 2px 5px rgba(0,0,0,0.1); /* subtle shadow */
    border-radius: 5px; /* rounded corners */
}

.country-select {
    margin-top: 10px;
    margin-left: 10px;
    width: 97%
}