@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-toast-container .Toastify__toast {
  min-width: 455px; /* Minimum width of the toast */
  max-width: 80%; /* Maximum width of the toast */
  word-wrap: break-word; /* Break words onto the next line if they are too long */

}

/* Overrides default react-toastify positioning */


/* Adjusts the toast to the center */
.Toastify__toast {
  margin-left: -15%; /* Move the toast slightly to the left */
  margin-right: auto;
}

